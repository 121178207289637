@import '@/styles/artifacts.scss';




























































































.no-data-message {
  margin-top: 10px;
}
