@import '@/styles/artifacts.scss';


























































































































































































































































































.info-audit-message {
  margin-bottom: 10px;
}
